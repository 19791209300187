import React, { Component } from "react"

/* Constants */
import { utils } from "../constants"


/* Component Check */
class Check extends Component {

    render = () => {

        const { onClick } = this.props

        return <div onClick={() => onClick()} className="game-button check-button">{utils.translate("CHECK")}</div>
    }

}

export default Check