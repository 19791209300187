import React, { Component } from "react"

/* Components */
import { Chip } from "@alicorn/ep-ui"

/* Constants */
import { utils, sound } from '../constants'
import { CHOICE, PRE_FLOP, FLOP, TURN_RIVER } from "../constants/status"


/* Widget Rates */
class Rates extends Component {

    /* Get Color */
    getColor = value => {

        const { chips } = this.props

        let color = chips[0].color

        chips.forEach(item => {
            if (item.value <= value) {
                color = item.color
            }
        })

        return color
    }

    /* DRAW ANIMATION */
    _animation = (target) => {

        const { list, box } = this.props
        const final = list.filter(e => parseInt(e.box) === parseInt(box))

        return (
            <div className="animated-chips">
                {final.map((chip, index) => {
                    const color = this.getColor(chip.value)

                    if (chip.target === target) {
                        return (
                            <div className={`animated-chip ${chip.type === "add" ? "visible" : "hidden"}`} key={`${index}`}>
                                <Chip size={58} color={color} value={chip.value} />
                            </div>
                        )
                    }

                    return null
                })}
            </div>
        )
    }

    add = (target) => {
        const { status, selected, all, ante, trips, setStake, list, setList, balance, game, chips, currency, volume, box } = this.props

        let stake = { ante: ante, trips: trips }

        const { anteList, tripsList } = all

        const secondBox = box ? 0 : 1
        const secondBoxTotal = anteList[secondBox] * 4 + tripsList[secondBox]

        if (status === "CHOICE" && chips[selected]) {
            const value = chips[selected].value

            if (target === 'ante') {
                stake.ante = ante + value
            }
            else {
                if (!ante) {
                    return
                }
                stake.trips = trips + value
            }

            if ((stake.ante * 4 + stake.trips + secondBoxTotal) > balance) {
                utils.toggleError(utils.translate("You don't have enough funds"), "error", "nomoney")
                return
            }

            if ((ante + value > parseInt(game.max) && target === 'ante') || (trips + value > parseInt(game.max) && target === 'trips')) {
                if (target === 'ante') {
                    utils.toggleError(`${utils.translate("Maximum bid")} ${utils.getWithCurrency(game.max, currency)}`, "error", "maxbidante")
                }
                else {
                    utils.toggleError(`${utils.translate("Maximum bid for trips")} ${utils.getWithCurrency(game.max, currency)}`, "error", "maxbidtrips")
                }
                return
            }

            setStake(stake, box)

            list.push({ value, type: "add", target, box })
            setList(list)

            if (volume) {
                sound.play('sound', 0.2)
            }
        }
    }

    /* DRAW ANTE & BLIND */
    _ab = () => {

        const { status, ante, solution, result } = this.props
        const color = this.getColor(ante)
        const choice = status === CHOICE

        const isHidden = solution === "FOLD" || (result && result.result === "lose")
        const anteAnimation = choice && !ante ? "animated" : ""

        return (
            <div className="ante-blind">
                <div className='rhombus-outer'>
                    <div className='rhombus-inner'>
                        <div className={`rhombus ante ${anteAnimation}`} />
                        <div className={`rhombus blind ${anteAnimation}`} />
                    </div>
                </div>

                <div className='front'>
                    <div className={`ante`} onClick={() => this.add('ante')}>
                        <div className="ante-text">ANTE</div>
                        <div className={`ante-chip-box ${(isHidden) ? "fold" : "hidden"}`}>{(ante && ante > 0) ? <Chip size={58} color={color} value={ante} /> : null}</div>
                        {this._animation('ante')}
                        {this._winRate("ante")}
                    </div>
                    <div className={`blind`} onClick={() => this.add('ante')}>
                        <div className="ante-text">BLIND</div>
                        <div className={`ante-chip-box ${(isHidden) ? "fold" : "hidden"}`}>{(ante && ante > 0) ? <Chip size={58} color={color} value={ante} /> : null}</div>
                        {this._animation('ante')}
                        {this._winRate("blind")}
                    </div>
                </div>
            </div>
        )
    }

    /* ANTE & BONUS MULTIPLIER */
    _winRate = (type) => {

        const { result, tripsResult, ante, trips } = this.props

        let isWinRate = type === "ante" ? (ante && result && result.anteMultiplier && parseInt(result.anteMultiplier) > 0) : type === "blind" ? (ante && result && result.blindMultiplier && parseInt(result.blindMultiplier)) : (trips && tripsResult && tripsResult.multiplier && parseInt(tripsResult.multiplier))

        if (isWinRate) {

            const value = type === "ante" ? ante * result.anteMultiplier : type === "blind" ? ante * result.blindMultiplier : trips * tripsResult.multiplier
            const multiplier = type === "ante" ? result.anteMultiplier : type === "blind" ? result.blindMultiplier : tripsResult.multiplier
            const color = this.getColor(value)

            return (
                <div className="rate-win-ab">
                    <div className="rate-win-ab-text">{multiplier}x</div>
                    <div className="rate-win-ab-chip">
                        <Chip size={58} color={color} value={value} />
                    </div>
                </div>
            )
        }
    }

    /* DRAW TRIPS */
    _trips = () => {
        const { ante, trips, tripsResult, status, box } = this.props
        const color = this.getColor(trips)
        const choice = status === CHOICE
        const isHidden = tripsResult && tripsResult.multiplier === 0
        const active = ((choice && ante) || trips) && !isHidden
        const tripsAnimation = choice && ante && !trips ? "animated" : ""

        const style = box === 0 ? 'right' : ''

        return (
            <div className={`trips-box ${box} ${style}`}>
                <div className='trips-rhombus-outer'>
                    <div className='trips-rhombus-inner'>
                        <div className={`r-trips ${tripsAnimation}`} />
                    </div>
                </div>
                <div className="trips" onClick={() => this.add("trips")} style={{ opacity: active ? 1 : 0.5 }}>
                    <div className="trips-text">TRIPS</div>
                    <div className={`trips-chip-box ${(isHidden) ? "fold" : "hidden"}`}>{(trips && trips > 0) ? <Chip size={58} color={color} value={trips} /> : null}</div>
                    {this._animation('trips')}
                    {this._winRate("trips")}
                </div>
            </div>
        )

    }

    /* BET MULTIPLIER */
    _winBet = () => {

        const { bet, ante, result } = this.props

        if (bet && result && result.betMultiplier && parseInt(result.betMultiplier) > 0) {

            const value =  bet * ante * result.betMultiplier

            const color = this.getColor(value)

            return (
                <div className="rate-win-bet">
                    <div className="rate-win-bet-text">{result.betMultiplier}x</div>
                    <div className="rate-win-bet-chip">
                        <Chip color={color} value={value} />
                    </div>
                </div>
            )
        }
    }


    /* Draw BET */
    _bet = () => {

        const { status, bet, ante, solution, result, bottomBet } = this.props
        const value = ante * bet
        const color = this.getColor(value)

        let betX = 0
        if (status === PRE_FLOP) {
            betX = 4
        }
        if (status === FLOP) {
            betX = 2
        }
        if (status === TURN_RIVER) {
            betX = 1
        }

        return (
            <div onClick={() => betX && bottomBet(betX)} className="bet" style={{ opacity: status === "CHOICE" ? 0.6 : 1 }}>
                <div className="bet-text">BET</div>
                {(bet && bet > 0) ? <div className={`bet-chip-box ${(solution === "FOLD" || (result && result.result === "lose")) ? "fold" : "hidden"}`}> <Chip color={color} value={value} /></div> : null}
                {this._winBet()}
            </div>
        )
    }

    render = () => {

        const { status, box, gameBox } = this.props

        let style = gameBox === "all" ? box === 0 ? "right" : "left" : ""

        if (status === CHOICE) {
            style += " rates-choice"
        }

        return (
            <div className={`rates ${style}`}>

                {this._trips()}
                <div className="ab-bet-box">
                    {this._ab()}
                    <div className="u-bet">
                        {this._bet()}
                    </div>
                </div>
            </div>
        )
    }

}

export default Rates