import React, { Component } from "react"

import { Picture } from "@alicorn/ep-ui"

import { env, utils } from "../constants"



/* Component Payout */
class Payout extends Component {

    render = () => {

        const { game, currency } = this.props
        const { min, maxTrips } = game

        return (
            <div className="payout-container">

                <div className="game-payout-row-box">

                    <div className="game-payout-row head">
                        <p className="game-payout-row-title" />
                        <p>ANTE</p>
                        <p>BET</p>
                    </div>

                    <div className="game-payout-row">
                        <p className="game-payout-row-title">Рука дилера проходит квалификацию (пара или выше), вы выигрываете</p>
                        <p>Выигрыш 1:1</p>
                        <p>Выигрыш 1:1</p>
                    </div>

                    <div className="game-payout-row">
                        <p className="game-payout-row-title">Рука дилера не квалифицирована</p>
                        <p>Ничья</p>
                        <p>Выигрыш 1:1</p>
                    </div>
                </div>


                <div className="payout">

                    <div className="payout-head">{utils.translate("BLIND")}</div>

                    <div className="payout-box">
                        <div className="payout-name">{utils.translate("THREE OF A KIND")}</div>
                        <div className="payout-combination">
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">Q</div>
                                <Picture src={`${env.mediapoint}/images/suits/clubs.png`} alt="Clubs" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">Q</div>
                                <Picture src={`${env.mediapoint}/images/suits/diamond.png`} alt="Diamond" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">Q</div>
                                <Picture src={`${env.mediapoint}/images/suits/spades.png`} alt="Spades" />
                            </div>
                        </div>
                        <div className="payout-value">СТЕЙ</div>
                    </div>

                    <div className="payout-box">
                        <div className="payout-name">{utils.translate("STRAIGHT")}</div>
                        <div className="payout-combination">
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">A</div>
                                <Picture src={`${env.mediapoint}/images/suits/clubs.png`} alt="Clubs" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">2</div>
                                <Picture src={`${env.mediapoint}/images/suits/diamond.png`} alt="Diamond" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">3</div>
                                <Picture src={`${env.mediapoint}/images/suits/spades.png`} alt="Spades" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">4</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">5</div>
                                <Picture src={`${env.mediapoint}/images/suits/clubs.png`} alt="Clubs" />
                            </div>
                        </div>
                        <div className="payout-value">1:1</div>
                    </div>



                    <div className="payout-box">
                        <div className="payout-name">{utils.translate("FLUSH")}</div>
                        <div className="payout-combination">
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">3</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">6</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">9</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">10</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">Q</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                        </div>
                        <div className="payout-value">3:2</div>
                    </div>



                    <div className="payout-box">
                        <div className="payout-name">{utils.translate("FULL HOUSE")}</div>
                        <div className="payout-combination">
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">2</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">2</div>
                                <Picture src={`${env.mediapoint}/images/suits/clubs.png`} alt="Clubs" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">J</div>
                                <Picture src={`${env.mediapoint}/images/suits/clubs.png`} alt="Clubs" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">J</div>
                                <Picture src={`${env.mediapoint}/images/suits/diamond.png`} alt="Diamond" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">J</div>
                                <Picture src={`${env.mediapoint}/images/suits/spades.png`} alt="Spades" />
                            </div>
                        </div>
                        <div className="payout-value">3:1</div>
                    </div>



                    <div className="payout-box">
                        <div className="payout-name">{utils.translate("FOUR OF A KIND")}</div>
                        <div className="payout-combination">
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">4</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">4</div>
                                <Picture src={`${env.mediapoint}/images/suits/clubs.png`} alt="Clubs" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">4</div>
                                <Picture src={`${env.mediapoint}/images/suits/diamond.png`} alt="Diamond" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">4</div>
                                <Picture src={`${env.mediapoint}/images/suits/spades.png`} alt="Spades" />
                            </div>
                        </div>
                        <div className="payout-value">10:1</div>
                    </div>


                    <div className="payout-box">
                        <div className="payout-name">{utils.translate("STRAIGHT FLUSH")}</div>
                        <div className="payout-combination">
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">6</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">7</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">8</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">9</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">10</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                        </div>
                        <div className="payout-value">50:1</div>
                    </div>



                    <div className="payout-box">
                        <div className="payout-name">{utils.translate("ROYAL FLUSH")}</div>
                        <div className="payout-combination">
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">10</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">J</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">Q</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">K</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">A</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                        </div>
                        <div className="payout-value">400:1</div>
                    </div>

                </div>

                <div className="bonus-payout">

                    <div className="bonus-payout-head">{utils.translate("TRIPS")}</div>

                    {/* <div className="bonus-payout-rate">{utils.getWithCurrency(min, currency)} - {utils.getWithCurrency(maxTrips, currency)}</div> */}

                    <div className="bonus-payout-box">
                        <div className="bonus-payout-name">{utils.translate("ROYAL FLUSH")}</div>
                        <div className="bonus-payout-value">200:1</div>
                    </div>

                    <div className="bonus-payout-box">
                        <div className="bonus-payout-name">{utils.translate("STRAIGHT FLUSH")}</div>
                        <div className="bonus-payout-value">100:1</div>
                    </div>

                    <div className="bonus-payout-box">
                        <div className="bonus-payout-name">{utils.translate("FOUR OF A KIND")}</div>
                        <div className="bonus-payout-value">40:1</div>
                    </div>

                    <div className="bonus-payout-box">
                        <div className="bonus-payout-name">{utils.translate("FULL HOUSE")}</div>
                        <div className="bonus-payout-value">8:1</div>
                    </div>

                    <div className="bonus-payout-box">
                        <div className="bonus-payout-name">{utils.translate("FLUSH")}</div>
                        <div className="bonus-payout-value">5:1</div>
                    </div>

                    <div className="bonus-payout-box">
                        <div className="bonus-payout-name">{utils.translate("STRAIGHT")}</div>
                        <div className="bonus-payout-value">4:1</div>
                    </div>

                    <div className="bonus-payout-box">
                        <div className="bonus-payout-name">{utils.translate("THREE OF A KIND")}</div>
                        <div className="bonus-payout-value">3:1</div>
                    </div>

                </div>
            </div>
        )
    }

}

export default Payout