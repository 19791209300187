const ERROR = "ERROR"
const CHOICE = "CHOICE"
const DEALING = "DEALING"
const GAME = "GAME"
const LOADING = "LOADING"

const PRE_FLOP = 'PREFLOP'
const FLOP = 'FLOP'
const TURN_RIVER = 'TURNRIVER'

export { ERROR, CHOICE, DEALING, GAME, LOADING, PRE_FLOP, FLOP, TURN_RIVER }