import React, { PureComponent } from "react"

/* Constants */
import { utils, closed } from '../constants'

/* Picture */
import { Card } from "../components"

/* Widget Holdem Table Cards */
class TableCards extends PureComponent {
    constructor() {
        super()
        this.state = {

        }
    }

    _result = (box) => {
        const { data } = this.props
        const { playerGame, dealerGame } = data

        let style = ""
        let text = ""
        const game = box === "dealer" ? dealerGame : playerGame && playerGame[box] ? playerGame[box] : false

        if (game && game.level === 0) {
            style = "red"
            text = utils.translate('NO GAME')
        }

        if (game && game.level > 0) {
            style = "green"
            text = utils.translate(game.name)
        }

        if (game) {
            return <p className={`table-cards-head-result ${style}`}>{text}</p>
        }

        return null
    }

    _spaces = (array, additional = false) => {
        const style = additional ? "additional" : ""
        return (
            <div className={`table-cards-spaces ${style}`}>
                {array.map(key =>
                    <div key={`${key}`} className="table-card-space">
                        <div className="table-card-space-circle">
                            <div className="table-card-space-diamond"></div>
                        </div>
                        <div className="table-card-space-round" />
                    </div>
                )}
            </div>
        )
    }

    _card = (card, open = true) => {
        return (
            <div className="table-card" key={`d-${card.uuid}`}>
                <div className={`table-flip-card  ${open ? 'open' : 'hidden'}`}>
                    <div className="table-flip-card-inner">
                        <div className="table-flip-card-front">
                            <img src={closed.image} alt="Closed" />
                        </div>
                        <div className={`table-flip-card-back`}>
                            <div>
                                {open ? <Card data={card.name} /> : <img src={closed.image} alt="Closed" />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render = () => {

        const { data, boxRender, choice } = this.props
        const { dealerPreflopCards, additionalCards, playerPreflopCards, gameBox } = data
        const dealerCards = utils.getUniqueValues(dealerPreflopCards, "uuid")
        const additional = utils.getUniqueValues(additionalCards, "uuid")

        const style = choice ? "table-choice" : ""

        return (
            <div className="table-cards-box">
                <div className={`table-cards-box-inner ${style}`}>

                    {/* Delaer cards */}
                    <div className="table-cards">
                        <div className="table-cards-head">
                            <p className="table-cards-head-title">{utils.translate("DEALER")}</p>
                            {this._result("dealer")}
                        </div>
                        {
                            dealerCards.map((card, index) => {
                                const open = (dealerCards[0] && dealerCards[0].id) ? true : false
                                if (index < 2) {
                                    return this._card(card, open)
                                }
                                return null
                            })
                        }
                        {this._spaces([1, 2])}
                    </div>

                    <div className="table-middleware-box" />

                    {/* Additional cards */}
                    <div className="table-cards additional">
                        <div className="table-cards-head">
                            <p className="table-cards-head-title">{utils.translate("ADDITIONAL CARDS")}</p>
                        </div>
                        {
                            additional.map((card, index) => {
                                if (index < 5) {
                                    return this._card(card)
                                }
                                return null
                            })
                        }
                        {this._spaces([1, 2, 3, 4, 5])}
                    </div>

                    <div className="table-middleware-box player" />

                    <div className={`table-player-box ${gameBox}`}>
                        {
                            playerPreflopCards.map((cards, index) => {
                                const playerCards = utils.getUniqueValues(cards, "id")
                                if (gameBox === "all" || gameBox === index) {
                                    return (
                                        <div className="table-player-box-inner" key={`p-cards-${index}`}>
                                            <div className="table-cards" key={`table-card-${index}`}>
                                                <div className="table-cards-head">
                                                    <p className="table-cards-head-title">{utils.translate("BOX")} {index + 1}</p>
                                                    {this._result(index)}
                                                </div>
                                                {
                                                    playerCards.map((card, index) => {
                                                        if (index < 2) {
                                                            return this._card(card)
                                                        }
                                                        return null
                                                    })
                                                }
                                                {this._spaces([1, 2])}
                                            </div>

                                            {boxRender(index)}
                                            
                                        </div>

                                    )
                                }
                                return null
                            })
                        }
                    </div>
                </div>
            </div>
        )

    }

}

export default TableCards