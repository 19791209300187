import React, { Component } from "react"


/* Component Bet */
class Bet extends Component {

    render = () => {

        const { onClick, value, index } = this.props

        return <div onClick={() => onClick(value)} className={`game-button bet-button btn-${index}`}>BET {value}X</div>
    }

}

export default Bet