import React, { PureComponent } from "react"
import { Card } from "../components"

class BoxCards extends PureComponent {

    _blured = (card, type) => {
        const { data } = this.props
        const { playerGame, dealerGame } = data

        let cards = []
        const game = type === 'player' ? playerGame : dealerGame

        let cnt = 0

        if (type && game && game.data) {
            cards = game.data
        }

        cards.forEach(tempCard => {
            if (tempCard.status) {
                cnt += 1
            }
        })

        const i = cards.findIndex(e => e.id === card.id && e.status === true)

        if (i === -1 && cnt) {
            return true
        }

        return false

    }

    /* Card */
    _card = (card, type) => {
        const style = (card.player || card.dealer) ? "animated" : ""
        const blured = this._blured(card, type) ? "blured" : ""
        return (
            <div className={`box-card ${blured}`} key={`d-${card.uuid}`}>
                <div className="box-flip-card">
                    <div className="box-flip-card-inner">
                        <div className={`box-flip-card-back`}>
                            <div className={style}>
                                <Card data={card.name} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render = () => {

        const { data, box } = this.props
        const { playerCards, dealerCards, playerGame, dealerGame } = data

        const style = box === 0 ? "right" : "left"
        const boxCardsStyle = playerCards.length > 2 ? "center" : style

        return (
            <div className={`box-cards-block ${style}`}>
                {dealerCards && dealerCards[0] ?
                    <div className="box-cards-block-inner dealer">
                        <p className="player-text">Дилер</p>
                        <div className={`box-cards ${boxCardsStyle}`}>
                            {
                                dealerCards.map(card => {
                                    return this._card(card, "dealer")
                                })
                            }
                        </div>
                        <p className="result-name">{dealerGame ? dealerGame.name : ""}</p>
                    </div>
                    : null
                }

                {playerCards && playerCards[0] ?
                    <div className="box-cards-block-inner">
                        <p className="player-text">Игрок</p>
                        <div className={`box-cards ${boxCardsStyle}`}>
                            {
                                playerCards.map(card => {
                                    return this._card(card, "player")
                                })
                            }
                        </div>
                        <p className="result-name">{playerGame ? playerGame.name : ""}</p>
                    </div>
                    : null
                }


            </div>
        )

    }

}

export default BoxCards