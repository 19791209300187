import React, { Component } from "react"

/* Constants */
import { utils, closed } from '../constants'

/* Picture */
import { Card } from "../components"

/* Widget Player Cards */
class PlayerCards extends Component {

    /* Draw Result */
    _result = () => {

        /* Fields */
        const { game } = this.props

        if (game) {

            if (game.level === 0) {
                return <div className="player-game-status red">{utils.translate('NO GAME')}</div>
            }

            if (game.level > 0) {

                const text = `${utils.translate(game.name)}${game.hasAceKing ? ` + ${utils.translate("ACE KING")}` : ""}`
                let size = "default"
                if (text.length > 20) {
                    size = "smaller"
                }

                return <div className={`player-game-status green ${size}`}>{text}</div>
            }

        }

    }

    /* Card status */
    status = card => {

        let status = "player"
        if (card.player) {
            status = "animated"
        }

        return status
    }


    render = () => {

        const { cards, solution, box, status, ante, game } = this.props
        const playerCards = utils.getUniqueValues(cards, "id")

        const style = playerCards.length > 2 ? "fixed" : ""

        const gameData = game && game.data ? game.data : []

        let cnt = 0
        playerCards.forEach(card => {
            const i = gameData.findIndex(e => e.id === card.id && e.status === true)
            if (i !== -1) {
                cnt += 1
            }
        })

        return (
            <div className="player-container" style={{ opacity: status === "CHOICE" || solution === "FOLD" || (status !== "CHOICE" && box === 1 && ante === 0) ? 0.5 : 1 }}>

                <div className="player-container-head">
                    <div className="player-container-title">{utils.translate("BOX")} {box + 1}</div>
                    {this._result()}
                </div>

                <div className={"player-cards"}>
                    {playerCards.map((card, index) => {
                        const i = gameData.findIndex(e => e.id === card.id && e.status === true)
                        const cardStyle = playerCards.length === 5 && cnt && i === -1 ? "blured" : ""
                        return (
                            <div className={`player-card ${style} ${cnt} ${cardStyle}`} key={`p-${card.id}`}>
                                <div className="player-flip-card open">
                                    <div className="player-flip-card-inner">
                                        <div className="player-flip-card-front">
                                            <img src={closed.image} alt="Closed" />
                                        </div>
                                        <div className="player-flip-card-back">
                                            <div className={`${this.status(card)}`}>
                                                <Card data={card.name} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    )}
                </div>

                {
                    playerCards.length <= 2 ?
                        <div className="player-spaces">
                            {[1, 2].map(key =>
                                <div key={`${key}`} className="player-space">
                                    <div className="player-space-circle">
                                        <div className="player-space-diamond"></div>
                                    </div>
                                    <div className="player-space-round" />
                                </div>
                            )}
                        </div>
                        : null
                }


            </div >
        )
    }

}

export default PlayerCards