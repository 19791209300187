import React, { Component } from "react"

/* Constants */
import { utils } from "../constants"


/* Component Fold */
class Fold extends Component {

    render = () => {

        const { onClick } = this.props
        return (
            <div onClick={() => onClick()} className="game-button fold-button">{utils.translate("FOLD")}</div>
        )
    }

}

export default Fold