import React, { Component } from "react"

/* Constants */
import { utils } from "../constants"

/* Component START */
class Start extends Component {

    render = () => {

        const { ante, onClick } = this.props

        return <div onClick={() => onClick()} className="game-button start-button" style={{ opacity: utils.valueLength(ante) > 0 ? 1 : 0.5 }}>{utils.translate("START")}</div>
    }

}

export default Start